import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": true,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["system", "frontend", "backend"],
  "status": ["completed"],
  "title": "IBIZ",
  "subtitle": "Path finding in supermarket with lights in the ceiling",
  "description": "",
  "years": ["2018", "2019"],
  "institutions": ["idemolab"],
  "location": "cph",
  "keywords": {
    "topics": ["iot", "api"],
    "tools": ["python", "adobe xd", "raspberry pi", "phillips hue", "flask", "boostrap"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer & Developer"
  }, {
    "name": "Mikkel Leth Olsen",
    "role": "Supervisor"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # IBIZ */
    }
    <h2>{`Description`}</h2>
    <p>{`During my internship at IdemoLab I worked on various projects, this is particularly mentionable.
A concept to help customers in a MENY supermarket finding product categories amongst on the isles.
Light bulbs in the ceiling animated the route they should follow.
An tablet with a web-application worked as the interface between the user and the system.
Researching how smart technology can benefit people in retail stores
Various prototyping projects, and a system for customers in supermarkets to easier find products with light guidance.
As a part of large project collaboration between FORCE Technology, Alexandra Institutet and Meny in order to research in how new technology might improve user experience while grocery shopping.
I did a project to make finding the location of products easier.
Through an interface you could find a product in a database, and then get directed by interactive lights in the roof who would lead you to the aisle.`}</p>
    <h2>{`Refernces`}</h2>
    <h2>{`Activities`}</h2>
    <ul>
      <li parentName="ul">{`System and functionality design`}</li>
      <li parentName="ul">{`UX`}</li>
      <li parentName="ul">{`Web development`}</li>
      <li parentName="ul">{`IoT`}</li>
      <li parentName="ul">{`Prototyping: Lasercutting`}</li>
      <li parentName="ul">{`Graphic Illustration`}</li>
    </ul>
    <h2>{`Mentionable Results`}</h2>
    <h2>{`Links / References`}</h2>
    <p><a parentName="p" {...{
        "href": "https://videnscenterportalen.dk/dsf/2019/03/19/artikel-kunder-i-bevaegelse/"
      }}>{`https://videnscenterportalen.dk/dsf/2019/03/19/artikel-kunder-i-bevaegelse/`}</a>{`
`}<a parentName="p" {...{
        "href": "https://videnscenterportalen.dk/dsf/2018/12/19/internet-of-things-i-meny/"
      }}>{`https://videnscenterportalen.dk/dsf/2018/12/19/internet-of-things-i-meny/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://youtu.be/-5A_8_3b5p4"
      }}>{`https://youtu.be/-5A_8_3b5p4`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      